import * as React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { KarlaInput, ShopLayout, Row } from '../../components/Layout';
import MailingListSignup from '../../components/MailingListSignup';
import SocialLinks from '../../components/SocialLinks';
import KarlaLogoBlack from '../../images/karla_logo_b.svg';

const StyledSectionHeader = styled.h2`
  margin-bottom: 8px;
`;
const StyledKarlaLogo = styled(KarlaLogoBlack)`
  margin-top: 20px;
  width: 127px  
`;
const StyledText = styled.p`
  margin: 20px 0px 4px 0px;
  font-size: 24px;
  cursor: pointer;
`;

function Success({ location }) {
  const { state = {} } = location;
  const { trackingNumber } = state;
  const { t } = useTranslation();

  return (
    <ShopLayout>
      <Row>
        <StyledSectionHeader>
          {t('successPage.trackingRecognized')}
        </StyledSectionHeader>
      </Row>
      <Row>
        <KarlaInput type="text" value={trackingNumber} disabled />
      </Row>
      <Row>
        <StyledKarlaLogo />
      </Row>
      <Row>
        <StyledText>
          {t('successPage.emailDisclaimerNegative.0')}
          <br />
          <br />
          {t('successPage.emailDisclaimerNegative.1')}
        </StyledText>
      </Row>

      <MailingListSignup state={state} />
      <SocialLinks />
    </ShopLayout>
  );
}

export default Success;
